import { AxiosError }  from 'axios';
import { defineStore } from 'pinia';
import { ref }         from 'vue';

import CountryApi       from '@/api/country-api';
import { AddressType }  from '@/types/address';
import { Country }      from '@/types/country';
import { CountryState } from '@/types/countryState';
import { captureError } from '@/utils/error-logger';

const useCountryStore = defineStore('country', () => {
    const deliveryCountries = ref<Country[]>([]);
    const invoiceCountries  = ref<Country[]>([]);
    const countryStates     = ref<CountryState[]>([]);

    function reset(): void {
        deliveryCountries.value = [];
        invoiceCountries.value = [];
        countryStates.value = [];
    }

    /**
     * Fetches the delivery address countries from backend, set store value and return the value
     */
    async function fetchDeliveryCountries(): Promise<Country[]> {
        return CountryApi.getCountriesForDeliveryAddresses()
            .then(response => {
                deliveryCountries.value = response.data;

                return deliveryCountries.value;
            })
            .catch((error: AxiosError) => {
                deliveryCountries.value = [];

                captureError(error);

                return Promise.reject(error);
            });
    }

    /**
     * Fetch the invoice address countries from backend, set store value and return the value
     */
    async function fetchInvoiceCountries(): Promise<Country[]> {
        return CountryApi.getCountriesForInvoiceAddresses()
            .then(response => {
                invoiceCountries.value = response.data;

                return invoiceCountries.value;
            })
            .catch((error: AxiosError) => {
                invoiceCountries.value = [];

                captureError(error);

                return Promise.reject(error);
            });
    }

    /**
     * Fetch the country states from backend, set store value and return the value
     */
    async function fetchCountryStates(): Promise<CountryState[]> {
        return CountryApi.getCountryStates()
            .then(response => {
                countryStates.value = response.data;

                return countryStates.value;
            })
            .catch((error: AxiosError) => {
                countryStates.value = [];

                captureError(error);

                return Promise.reject(error);
            });
    }

    /**
     * Only fetches if store value is null and returns the delivery address countries value
     */
    async function getDeliveryCountries(): Promise<Country[]> {
        if (deliveryCountries.value.length === 0) {
            return fetchDeliveryCountries();
        }

        return new Promise(resolve => resolve(deliveryCountries.value));
    }

    /**
     * Only fetches if store value is null and returns the invoice address countries value
     */
    async function getInvoiceCountries(): Promise<Country[]> {
        if (invoiceCountries.value.length === 0) {
            return fetchInvoiceCountries();
        }

        return new Promise(resolve => resolve(invoiceCountries.value));
    }

    /**
     * Proxy method to allow calling get*Countries based on variable
     */
    async function getCountriesByAddressType(type: AddressType): Promise<Country[]> {
        if (type === AddressType.INVOICE) {
            return getInvoiceCountries();
        }

        return getDeliveryCountries();
    }

    /**
     * Exposes store values and methods
     */
    return {
        deliveryCountries,
        invoiceCountries,
        countryStates,
        reset,
        fetchDeliveryCountries,
        fetchInvoiceCountries,
        fetchCountryStates,
        getDeliveryCountries,
        getInvoiceCountries,
        getCountriesByAddressType,
    };
});

export default useCountryStore;
